import { CarouselController } from "../controller/carousel-controller";

/**
 * Returns the target index of the "scroll to next" function.
 *
 * The resulting motion wraps when the last slide is currently shown.
 */
export const getTargetIndexOfScrollToNext = (
  controller: CarouselController
) => {
  const overflowIndex = controller.options.loop
    ? 0
    : controller.getCurrentIndex();

  return controller.getCurrentIndex() < controller.getNumberOfSlides() - 1
    ? controller.getCurrentIndex() + 1
    : overflowIndex;
};

export const scrollToNextSlide = (controller: CarouselController) =>
  controller.slideTo(getTargetIndexOfScrollToNext(controller));

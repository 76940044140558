import "./general-prev-next.scss";
import "./control-next.scss";
import {
  CarouselAdditionInitializationBlock,
  forEachRegisteredCarouselController,
} from "../carousel-handles";
import { scrollToNextSlide } from "./scroll-to-next-slide";
import { currentCarouselQuerySelectorAll } from "../selector/current-selector";

export const CAROUSEL_NEXT_CLASS_NAME = "carousel-control-next";

const initializeNextControlElements: CarouselAdditionInitializationBlock = (
  carouselRootElement,
  controller
) => {
  for (const nextControlElement of currentCarouselQuerySelectorAll(
    carouselRootElement,
    `.${CAROUSEL_NEXT_CLASS_NAME}`
  )) {
    nextControlElement.addEventListener("click", () =>
      scrollToNextSlide(controller)
    );
  }
};

forEachRegisteredCarouselController(initializeNextControlElements);
